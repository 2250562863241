import axios from 'axios';
import D_IUser from '../entities/D_IUser';
import { D_IRef } from '../entities/D_IRef';
import axiosInstance from './instances/axios.instance';

/**
 * DatabaseHelper class to interact with the database
 * @name DatabaseHelper
 * @type Class
 * @param baseUrl: get it form common/AddressFactory
 * @desc Class to interact with the database
 * @method getUsers
 * @method getUserByAddress
 * @method createUser
 * @method updateUser
 * @method deleteUser
 * @method getReferences
 * @method getReferencesUser
 * @method createReference
 * @method updateReference
 * @method deleteUser
 */
class DatabaseHelper {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getUsers(): Promise<any> {
        const url = `${this.baseUrl}/user`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error(`Error while making GET request to ${url}:`, error);
        }
    }

    //LOGIB;LOGOUT;SIGNUP;LOGIN;UPDATE;DELETE

    public async login(data: Partial<D_IUser>): Promise<any> {
        const { email, password } = data;
        const response = await axiosInstance.post('/auth/login', { email, password });
        console.log('response', response)
        if (response.data.token) {
            console.log("Token", response.data.token)
            localStorage.setItem('token', response.data.token);
        }
        return response.data;
    }

    public async getMe(token: string): Promise<any> {
        if (!token) {
            return null;
        }
        const response = await axiosInstance.get('/auth/me');
        return response.data;
    }

    public async logout(): Promise<any> {
        await axios.post(`${this.baseUrl}/auth/logout`);
        localStorage.removeItem('token');
    };

    public async signup(data: Partial<D_IUser>): Promise<any> {
        const { username, email, password, address } = data;
        const response = await axios.post(`${this.baseUrl}/auth/signup`, { username, email, password, address });
        console.log('response', response);
        return response.data;
    }

    public async getUserByAddress(address?: string): Promise<any> {
        if (address) {
            const url = `${this.baseUrl}/user/address/:${address}`;
            try {
                const response = await axios.get(url);
                return response.data;
            } catch (error) {
                console.error(`Error while making GET request to ${url}:`, error);

            }
        } else {
            console.log('WARNING: Function getUserByAddress will not work without an address parameter. Will be skipped.')
        }
    }

    public async createUser(data: Partial<D_IUser>): Promise<any> {
        const url = `${this.baseUrl}/user`;
        try {
            const response = await axios.post(url, data);
            return response.data;
        } catch (error) {
            console.error(`Error while making POST request to ${url}:`, error);

        }
    }

    public async updateUser(address: string, data: Partial<D_IUser>): Promise<any> {
        const url = `${this.baseUrl}/user/address/:${address}`;
        try {
            const response = await axios.put(url, data);
            return response.data;
        } catch (error) {
            console.error(`Error while making PUT request to ${url}:`, error);

        }
    }

    public async deleteUser(userId: string): Promise<any> {
        const url = `${this.baseUrl}/user/:${userId}`;
        try {
            const response = await axios.delete(url);
            return response.data;
        } catch (error) {
            console.error(`Error while making DELETE request to ${url}:`, error);

        }
    }

    //* dev - methods for ref program class and user class *//

    public async getReferences(): Promise<any> {
        const url = `${this.baseUrl}/references`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error(`Error while making GET request to ${url}:`, error);

        }
    }

    public async getReferencesUser(user: string): Promise<any> {
        const url = `${this.baseUrl}/references/user/:${user}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error(`Error while making GET request to ${url}:`, error);

        }
    }

    public async createReference(data: Partial<D_IRef>): Promise<any> {
        const url = `${this.baseUrl}/references`;
        try {
            const response = await axios.post(url, data);
            return response.data;
        } catch (error) {
            console.error(`Error while making POST request to ${url}:`, error);

        }
    }

    public async updateReference(_id: string, data: Partial<D_IRef>): Promise<any> {
        const url = `${this.baseUrl}/references/:${_id}`;
        try {
            const response = await axios.put(url, data);
            return response.data;
        } catch (error) {
            console.error(`Error while making PUT request to ${url}:`, error);

        }
    }

}

export default DatabaseHelper;