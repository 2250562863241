/* eslint-disable @typescript-eslint/no-unused-vars */

import { IEtherContext } from "./IEtherContext";
import { IAccount } from "../entities/IAccount";
import { IWalletInfo } from "../entities/IWalletInfo";
import { IToast } from "../entities/IToast"
import { BigNumber, Contract, ContractTransaction, Transaction, ethers } from "ethers";
import AddressFactory from "../common/AddressFactory";
import { IUser } from "../entities/IUser";

declare global {
    interface Window {
        ethereum?: any;
    }
}

interface Window {
    ethereum?: any;
}

interface IAsset {
    name: string;
    symbol: string;
    address: string;
    logo: string;
    disabled: boolean;
}

// @ts-ignore
const { ethereum } = window;

class LinkFactory {
    static getTransctionLink(txHash: string, chainId?: number, name?: string) {
        return this.getLink(name ?? 'Transaction HASH', `${chainId === 11155111 ? 'testnet: ' : ''}tx => ${txHash}`);
    }

    static getLink(name: string, url: string) {
        return { name: name, url: url };
    }
}

type Listener = (...args: Array<any>) => void;

export default class EtherHelper {

    public static getChainId(): number { return process.env.REACT_APP_CHAINID ? Number(process.env.REACT_APP_CHAINID) : 137; }

    private static initProvider(): ethers.providers.Web3Provider {
        const chainid = this.getChainId();
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(chainid)) as ethers.providers.Web3Provider;
        if (process.env.REACT_APP_WEB && process.env.REACT_APP_WEB === "1") return provider;
        return ethereum ? new ethers.providers.Web3Provider(ethereum) : provider;
    }

    public static initialAccount(): IAccount {
        return {
            balance: undefined,
            ethAmount: 0,
            connected: false
        } as IAccount;
    }

    public static initialToast(): IToast {
        return {
            toastId: undefined,
            toastDescription: '',
            toastStatus: "success",
            toastTitle: '',
            toastLink: undefined
        } as IToast;
    }

    public static handleLogin(context: IEtherContext, dataLogin: Partial<IUser>):  Promise<IEtherContext> {
        //user exists and wallet too
        return new Promise((resolve, reject) => {
            if (dataLogin) {
                console.log("EtherHelper.handleLogin: ", JSON.stringify(dataLogin));
                return resolve({ ...context, address: dataLogin.address, email: dataLogin.email, connected: context.addressSigner?.toLocaleLowerCase() === dataLogin.address?.toLocaleLowerCase() ? true : false});
            }
            return reject(context);
        });
    }

    public static handleSignUp(context: IEtherContext, dataSignUp: Partial<IUser>): Promise<IEtherContext> {
        return new Promise((resolve, reject) => {
            if (dataSignUp) {
                console.log("EtherHelper.handleSignUp: ", JSON.stringify(dataSignUp));
                return resolve({ ...context, address: dataSignUp.address, email: dataSignUp.email, connected: context.addressSigner?.toLocaleLowerCase() === dataSignUp.address?.toLocaleLowerCase() ? true : false });
            }
            return reject(context);
        });
    }

    public static async connect(context: IEtherContext): Promise<IEtherContext> {
        try {
            console.log("EtherHelper.connect");

            let accounts;
            const availableChains = AddressFactory.getAvailableChain();
            const desiredChainConfig = availableChains[context.chainId ?? 1];
            if (!desiredChainConfig) {
                console.error("Desired chain is not available.");
                return context;
            }

            const desiredChainId = desiredChainConfig.chainId;

            if (typeof window !== 'undefined' && (window as any).ethereum !== 'undefined') {
                const ethereum = (window as any).ethereum;

                if (ethereum.networkVersion !== String(desiredChainId)) {
                    try {
                        await ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: ethers.utils.hexlify(desiredChainId ?? 1) }]
                        });
                    } catch (err: any) {
                        if (err.code === 4902) {
                            await ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainName: desiredChainConfig.chainName,
                                        chainId: ethers.utils.hexlify(desiredChainId ?? 1),
                                        nativeCurrency: { name: desiredChainConfig.chainName, decimals: 18, symbol: desiredChainConfig.chainSymbol },
                                        rpcUrls: [desiredChainConfig.rpcUrl]
                                    }
                                ]
                            });
                        }
                    }
                }

                // Initialize provider after catching the chain
                const provider = EtherHelper.initProvider();
                if (!context.chainId) context = await this.getNetwork(provider, context);

                accounts = await provider.send("eth_requestAccounts", []);

                return this.queryProviderInfo({ ...context, addressSigner: accounts[0], connected: true }).then(this.querySignerInfo);
            }

            return context;

        } catch (error) {
            console.log("EtherHelper.connect FAILED: ", JSON.stringify(error))
            return context;
        }
    }

    public static getCurrentAvailableChains(): any {
        const currentChainId = this.getChainId();
        const availableChains = AddressFactory.getAvailableChain();
        return availableChains[currentChainId];
    }


    public static async getCurrentChainId(): Promise<number> {
        const provider = EtherHelper.initProvider();
        const network = await provider.getNetwork();
        return network.chainId ? BigNumber.from(network.chainId).toNumber() : 1;
    }

    public static async getNetwork(provider: ethers.providers.Web3Provider, context: IEtherContext): Promise<IEtherContext> {
        const network = await provider.getNetwork();
        const chainId = network.chainId ? BigNumber.from(network.chainId).toNumber() : 137;

        return {
            ...context,
            chainId: chainId,
            chainSymbol: network.ensAddress ? await provider.getCode(network.ensAddress) : "ETH"
        };
    }

    public static async disconnect(context: IEtherContext): Promise<IEtherContext> {
        this.disconnectListeners();
        return this.queryProviderInfo({ loaded: false, reload: true });
    }

    public static async querySignerInfo(context: IEtherContext): Promise<IEtherContext> {
        if (!context.addressSigner) return context;
        const provider = EtherHelper.initProvider();
        const chainId = EtherHelper.getChainId()
        // const provider = new ethers.providers.Web3Provider(ethereum);

        if (!context.chainId) context = await this.getNetwork(provider, context);

        const signer = provider.getSigner(context.addressSigner);

        function toNumberSafe(bn: BigNumber): number {
            try {
                return bn.toNumber();
            } catch (error) {
                console.error('Error converting BigNumber to number:', error);
                return 0; // o un valore predefinito appropriato in caso di errore
            }
        }

        const etherBalancePromise = signer
            .getBalance()
            .then((result: BigNumber) => context.balance = Number(ethers.utils.formatEther(result)))
            .catch((error: any) => console.log("EtherHelper.queryProviderInfo.ethBalance: ", JSON.stringify(error)));
        // context.nfts = [];
        const userAddress: string = context.addressSigner || '';

        await Promise.all([etherBalancePromise]);
        return context;
    }

    /*
    public static async getTokenURI(context: IEtherContext, tokenId: number) {
            if (context.connected) {
                const provider = EtherHelper.initProvider();
                const signer = provider.getSigner(context.addressSigner);
                const Factories = new Contract(AddressFactory.getFactoriesAddress(context.chainId ?? 11155111), DivitrendFactoriesABI, signer) as DivitrendFactories;
    
                const URIpath = await Factories.tokenURI(tokenId);
                const response = await fetch(URIpath + '.json');
                const tokenData = await response.json();
                return tokenData;
            }
        }
    */

    public static async queryProviderInfo(context: IEtherContext): Promise<IEtherContext> {
        if (context.loaded && !context.reload) return context;

        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);

        if (!context.chainId) context = await this.getNetwork(provider, context);

        //await Promise.all([]);

        return context;
    }

    public static async queryOwnerProviderInfo(context: IEtherContext): Promise<IEtherContext> {

        const provider = EtherHelper.initProvider();

        /*
        if (!context.chainId) context = await this.getNetwork(provider, context);
        await Promise.all([isTokenPausedPromise, isRewardsPausedPromise, isAutoSwapEnabledPromise,
            rewardsBalancePromise, taxTokensromise, liqTokensPromise, thresholdPromise]);
        */

        return context;
    }

    //#endregion


    public static connectChainListener(chainChanged: Listener) {
        ethereum?.on('chainChanged', chainChanged);
    }

    public static connectAccountListener(accountsChanged: Listener) {
        ethereum?.on('accountsChanged', accountsChanged);
    }

    public static connectErrorListener(error: Listener) {
        ethereum?.on("error", error);
    }

    public static async getBlockTimeStamp(): Promise<number> {
        const provider = EtherHelper.initProvider();
        const block = await provider.getBlock("latest");
        return block.timestamp * 1000;
    }

    public static disconnectListeners() {
        ethereum?.removeAllListeners();
    }
}