import { useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useInView,
} from "framer-motion";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import darkHero from "../static/images/darkHero.png";
import whiteHero from "../static/images/whiteHero.png";
import logo from "../static/images/cropped.png";

import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: "auto",
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  boxShadow:
    theme.palette.mode === "light"
      ? `0 0 1px rgba(75, 0, 130, 0.1), 1px 1.5px 2px -1px rgba(75, 0, 130, 0.15), 4px 4px 12px -2.5px rgba(75, 0, 130, 0.15)`
      : "0 0 1px rgba(48, 25, 52, 0.7), 1px 1.5px 2px -1px rgba(48, 25, 52, 0.65), 4px 4px 12px -2.5px rgba(48, 25, 52, 0.65)",
  outline: "1px solid",
  backgroundImage: `url(${
    theme.palette.mode === "light" ? whiteHero : darkHero
  })`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  outlineColor:
    theme.palette.mode === "light"
      ? "hsla(240, 100%, 20%, 0.5)" // Blu scuro
      : "hsla(240, 100%, 80%, 0.1)", // Blu chiaro
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: 500, // Reduced height for larger screens
  },
}));

const OverlayImage = styled("img")({
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto", // Adjust size as needed
  height: "auto",
});

export default function Hero() {
  const textRef = useRef<HTMLHeadingElement>(null);
  const { scrollYProgress } = useScroll({ target: textRef });
  const scaleY = useSpring(scrollYProgress, { stiffness: 400, damping: 150 });
  const x = useTransform(scaleY, [1, 0], ["-70vh", "5vh"]);
  const isInView = useInView(textRef, { once: false });
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:900px)");

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        padding: 0,
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 80% 60% at 50% -15%, hsl(220, 100%, 70%), transparent)" // Azzurrino
            : "radial-gradient(ellipse 80% 60% at 50% -15%, hsl(240, 100%, 80%), transparent)", // Blu chiaro
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 4, sm: 10, md: 8 },
          pb: { xs: 8, sm: 12, md: 10 },
          position: "relative",
        }}
      >
        {/* Centered Typography and Button */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
            mt: 8,
            opacity: isInView ? 1 : 0,
            transition: "opacity 1.5s ease-in-out",
          }}
        >
          <motion.div
            whileHover={{ scale: 1.1 }}
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Typography
              variant="h1"
              component="h1"
              ref={textRef}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                fontSize: {
                  xs: "clamp(2rem, 8vw, 4rem)",
                  md: "clamp(4rem, 10vw, 4.5rem)",
                },
                fontWeight: 600,
                fontFamily: "Zen Dots",
                textShadow: (theme) =>
                  theme.palette.mode === "light"
                    ? "8px 8px 10px rgba(255, 255, 255, 0.8)" // White outline for light theme
                    : "8px 8px 10px rgba(0, 0, 0, 0.8)", // Black outline for dark theme
              }}
            >
              ETHERCODE&nbsp;
            </Typography>
          </motion.div>
          <Typography
            textAlign="center"
            color="text.primary"
            sx={{
              width: { xs: "90%", sm: "80%", md: "70%" },
              fontSize: {
                xs: "clamp(0.8rem, 1rem, 1rem)",
                md: "clamp(1rem, 1rem, 1rem)",
              },
              fontFamily: "Zen Dots",
              fontWeight: 500,
              fontStyle: "italic",
              mb: 3,
              mt: 1,
              textShadow: (theme) =>
                theme.palette.mode === "light"
                  ? "2px 2px 4px rgba(255, 255, 255, 0.8)" // White outline for light theme
                  : "2px 2px 4px rgba(0, 0, 0, 0.8)", // Black outline for dark theme
            }}
          >
            " Transform cutting-edge digital ideas into Web3 realities.
            <br />
            Strategic consulting, customized tokenomics, and commissioned
            projects that push the boundaries of blockchain technology. "
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              variant="contained"
              color="primary"
              component={motion.button}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              sx={{
                fontWeight: 500,
                fontFamily: "Zen Dots",
                textTransform: "none", // Add this line
              }}
            >
              Free consultation
            </Button>
          </Box>
        </Box>
        {/* End Centered Typography and Button */}
        <motion.div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "auto",
            zIndex: 1,
            position: "relative",
          }}
        >
          <StyledBox
            id="image"
            sx={{
              width: "100%",
              height: isMobile ? 500 : isTablet ? 400 : "auto",
              position: "relative",
            }}
          />
          <motion.div
            animate={{
              scale: [1, 0.6, 1],
              rotate: [0, 360],
            }}
            transition={{ duration: 8, repeat: Infinity }} // Continuous loop
            style={{
              transformOrigin: "center",
              position: "absolute", // Ensure absolute positioning
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust position to center
              transition: "opacity 1s ease-in-out", // Smooth transition for opacity
            }}
          >
            <motion.div
              whileHover={{ scale: 1.2, rotate: 90 }}
              whileTap={{
                scale: 0.8,
                rotate: -720,
                borderRadius: "100%",
                transition: { duration: 1 },
              }}
            >
              <OverlayImage src={logo} alt="Overlay Image" />
            </motion.div>
          </motion.div>
        </motion.div>
      </Container>
    </Box>
  );
}
