import axios from 'axios';

const instance = axios.create({
    baseURL: 'http://localhost:5000/', // Cambia con l'URL del tuo backend
    headers: {
        'Content-Type': 'application/json',
    },
});

// Aggiungi un interceptor per inserire il token JWT nelle richieste
instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // O sessionStorage.getItem('token')
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

export default instance;
