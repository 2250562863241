import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import White from '../static/images/White.png';
import Black from '../static/images/Black.png';
import Trend from '../static/images/Trend.png';
import Logo from '../static/images/cropped.png';
import Shield from '../static/images/Shield.png';
import Cube from '../static/images/Cube.png';
import Contract from '../static/images/Contract.png';
import ContractItems from '../static/images/ContractItems.png';
import { styled } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const imageLight = `url(${White})`;
const imageDark = `url(${Black})`;
const imageTrend = `url(${Trend})`;
const imageShield = `url(${Shield})`;
const imageCube = `url(${Cube})`;
const imageContract = `url(${Contract})`;
const imageContractItems = `url(${ContractItems})`;

const items = [
    {
        icon: <VerifiedUserIcon />,
        title: 'Secure and reliable',
        description:
            'We adopt industry best practices to ensure the security of your project. We are constantly updated on new vulnerabilities and implement precautions to mitigate existing threats.',
    },
    {
        icon: <RocketLaunchIcon />,
        title: 'Expertise in Web3',
        description:
            'With our professional services, you can easily launch your idea into the world of web3 and beyond, beginning to be part of the future. We are committed to providing superior service by leveraging the latest technologies to provide innovative and cutting-edge solutions.',
    },
    {
        icon: <WorkspacePremiumIcon />,
        title: 'Quality assured',
        description:
            'Our focus on quality and excellence sets us apart in the Web3 landscape. We provide support via discord and telegram chat. Our experienced team is always available to help you with any questions.',
    },
];

interface ChipProps {
    selected?: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(({ theme, selected }) => ({
    ...(selected && {
        borderColor:
            theme.palette.mode === 'light'
                ? theme.palette.primary.light
                : theme.palette.primary.dark,
        background:
            'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        '& .MuiChip-label': {
            color: 'hsl(0, 0%, 100%)',
        },
    }),
}));

export default function Features() {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState<number>(0);
    const { ref, inView } = useInView({ triggerOnce: false });

    const handleItemClick = (index: number) => {
        if (selectedItemIndex !== index) {
            setSelectedItemIndex(index);
        }
    };

    return (
        <Container id="values" sx={{ py: { xs: 8, sm: 16 } }} ref={ref}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <div>
                        <Typography component="h2" variant="h3" color="text.primary" fontFamily="Zen Dots">
                           OUR VALUES
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            fontFamily={'Zen Dots'}
                            sx={{ mb: { xs: 2, sm: 4 }, mt: { xs: 1, sm: 2 } }}
                        >
                            Developing is our passion.
                             We provide high quality standards that differentiate us from amateur developers. 
                             Attention to detail and to the security side, professionalism and empathy enable us to meet all kinds of needs.
                             We are involved in developing: DeFi, cryptocurrency staking, DEX, NFT collections, NFT marketplaces, decentralized identity systems, decentralized governance (DAO) solutions, Web3 games and more. 
                        </Typography>
                    </div>
                    <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
                        {items.map(({ title }, index) => (
                            <Chip
                                key={index}
                                label={title}
                                onClick={() => handleItemClick(index)}
                                selected={selectedItemIndex === index}
                            />
                        ))}
                    </Grid>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        useFlexGap
                        sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
                    >
                        {items.map(({ icon, title, description }, index) => (
                            <motion.div
                                key={index}
                                initial={{ height: 'auto' }}
                                animate={{ height: selectedItemIndex === index ? 'auto' : 'fit-content' }}
                                transition={{ duration: 0.5 }}
                                style={{ width: '100%' }}
                            >
                                <Card
                                    component={Button}
                                    onClick={() => handleItemClick(index)}
                                    sx={(theme) => ({
                                        p: 3,
                                        width: '100%',
                                        background: 'none',
                                        ...(selectedItemIndex === index && {
                                            backgroundColor: 'action.selected',
                                            borderColor:
                                                theme.palette.mode === 'light'
                                                    ? 'primary.light'
                                                    : 'primary.dark',
                                        }),
                                        '&:hover': {
                                            background:
                                                theme.palette.mode === 'light'
                                                    ? 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)'
                                                    : 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                                            borderColor:
                                                theme.palette.mode === 'light'
                                                    ? 'primary.light'
                                                    : 'primary.dark',
                                            boxShadow:
                                                theme.palette.mode === 'light'
                                                    ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                                    : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                                        },
                                    })}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            textAlign: 'left',
                                            flexDirection: { xs: 'column', md: 'row' },
                                            alignItems: { md: 'center' },
                                            gap: 2.5,
                                        }}
                                    >
                                        <Box
                                            sx={(theme) => ({
                                                color:
                                                    theme.palette.mode === 'light' ? 'grey.400' : 'grey.600',
                                                ...(selectedItemIndex === index && {
                                                    color: 'primary.main',
                                                }),
                                            })}
                                        >
                                            {icon}
                                        </Box>
                                        <div>
                                            <Typography
                                                color="text.primary"
                                                fontWeight="medium"
                                                gutterBottom
                                                fontFamily="Zen Dots"
                                            >
                                                {title}
                                            </Typography>
                                            <AnimatePresence>
                                                {selectedItemIndex === index && (
                                                    <motion.div
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.5 }} // Slow appearance
                                                    >
                                                        <Typography
                                                            color="text.secondary"
                                                            variant="body2"
                                                            sx={{ mb: 1.5, textTransform: 'none', fontFamily: 'Zen Dots' }}
                                                        >
                                                            {description}
                                                        </Typography>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                            <AnimatePresence>
                                                {selectedItemIndex !== index && (
                                                    <motion.div
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.1 }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                mt: '1px',
                                                                ml: '2px',
                                                                color: 'primary.main',
                                                                fontFamily: 'Zen Dots',
                                                                fontSize: '0.9rem',
                                                                textTransform: 'none', // Ensure mixed case
                                                            }}
                                                        >
                                                            Click to expand
                                                        </Typography>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    </Box>
                                </Card>
                            </motion.div>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
                >
                    <AnimatePresence mode="wait">
                        {selectedItemIndex !== null && (
                            <Card
                                variant="outlined"
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: { xs: 'none', sm: 'flex' },
                                    pointerEvents: 'none',
                                    position: 'relative', // Added for positioning the Trend and Logo images
                                }}
                            >
                                <Box
                                    sx={{
                                        m: 'auto',
                                        width: '100%', // Extend to full width
                                        height: '100%', // Extend to full height
                                        backgroundSize: 'cover', // Cover the entire area
                                        backgroundImage: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? imageLight
                                                : imageDark,
                                    }}
                                />
                                {selectedItemIndex === 0 && (
                                    <>
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: inView ? 1 : 0 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }} // Increased duration
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundImage: imageShield,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <motion.div
                                                initial={{ opacity: 0, scale: 0 }}
                                                animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }}
                                                transition={{ delay: 1, duration: 1 }} // Increased duration
                                                style={{
                                                    width: '500px', // Adjust size as needed
                                                    height: '500px', // Adjust size as needed
                                                    backgroundImage: `url(${Cube})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                }}
                                            />
                                        </motion.div>
                                    </>
                                )}
                                {selectedItemIndex === 1 && (
                                    <>
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: inView ? 1 : 0 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }} // Increased duration
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundImage: imageTrend,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <motion.div
                                                initial={{ opacity: 0, scale: 0 }}
                                                animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }}
                                                transition={{ delay: 1, duration: 1 }} // Increased duration
                                                style={{
                                                    width: '200px', // Adjust size as needed
                                                    height: '200px', // Adjust size as needed
                                                    backgroundImage: `url(${Logo})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                }}
                                            />
                                        </motion.div>
                                    </>
                                )}
                                {selectedItemIndex === 2 && (
                                    <>
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: inView ? 1 : 0 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }} // Increased duration
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundImage: imageContract,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <motion.div
                                                initial={{ opacity: 0, scale: 0 }}
                                                animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }}
                                                transition={{ delay: 1, duration: 1 }} // Increased duration
                                                style={{
                                                    width: '500px', // Adjust size as needed
                                                    height: '500px', // Adjust size as needed
                                                    backgroundImage: `url(${ContractItems})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                }}
                                            />
                                        </motion.div>
                                    </>
                                )}
                            </Card>
                        )}
                    </AnimatePresence>
                </Grid>
            </Grid>
        </Container>
    );
}