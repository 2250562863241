import * as React from 'react';
import { useRef, useEffect } from "react";
import { motion, useScroll } from "framer-motion";
import { Container, useTheme, Link } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import FMG from '../static/images/fmg.png';
import Crooks from '../static/images/crooks.png';
import Hyena from '../static/images/hyena.png';
import Divitrend from '../static/images/divitrend.png';
import Burny from '../static/images/burny.png';

export default function Works() {
  const theme = useTheme();
  const ref = useRef<HTMLUListElement>(null);
  const { scrollXProgress } = useScroll({ container: ref });

  const projects = [
    { title: "Fury Mouse Gang", image: FMG, link: "https://minted.network/it/collections/cronos/0xb0a48bdcbb08a1817c8edf51e940bd3e74572a14", chain: "CRONOS" },
    { title: "Crooks Finance", image: Crooks, link: "https://crooks.finance/", chain: "CRONOS" },
    { title: "Hyena Hustlers", image: Hyena, link: "https://www.hyenahustlers.xyz/home", chain: "CRONOS" },
    { title: "Divitrend", image: Divitrend, link: "https://www.divitrend.finance/", chain: "ETH" },
    { title: "Burny", image: Burny, link: "https://burny.vip/", chain: "ETH (in progress)" },
  ];

  const isDarkTheme = theme.palette.mode === 'dark';

  useEffect(() => {
    const scrollContainer = ref.current;
    let scrollAmount = 0;
    let direction = 1;
    let isScrolling = true;

    const scrollStep = () => {
      if (scrollContainer && isScrolling) {
        scrollAmount += direction;
        scrollContainer.scrollLeft = scrollAmount;

        if (scrollAmount >= scrollContainer.scrollWidth - scrollContainer.clientWidth || scrollAmount <= 0) {
          direction *= -1;
        }
      }
    };

    const handleMouseEnter = () => {
      isScrolling = false;
    };

    const handleMouseLeave = () => {
      if (scrollContainer) {
        scrollAmount = scrollContainer.scrollLeft;
      }
      isScrolling = true;
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('mouseenter', handleMouseEnter);
      scrollContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    const scrollInterval = setInterval(scrollStep, 20);

    return () => {
      clearInterval(scrollInterval);
      if (scrollContainer) {
        scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
        scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);
  useEffect(() => {
    // Add global styles for WebKit browsers
    const styles = `
      .horizontal-scroll::-webkit-scrollbar {
        height: 4px; // Make scrollbar thinner
      }
      .horizontal-scroll::-webkit-scrollbar-track {
        background: rgba(0, 0, 139, 0.3); // darkblue with more transparency
      }
      .horizontal-scroll::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 255, 0.5); // blue with more transparency
        border-radius: 10px;
      }
    `;

    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, []);

  return (
    <Container id="works" style={{ fontFamily: 'Zen Dots, sans-serif', marginBottom: '100px'}}>
      <h1>We have had the pleasure of working with:</h1>
      <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
        <circle 
          cx="50" 
          cy="50" 
          r="30" 
          pathLength="1" 
          className="bg" 
          fillOpacity="0" 
          stroke={isDarkTheme ? 'white' : 'black'} 
        />
        <motion.circle
          cx="50"
          cy="50"
          r="30"
          color="white"
          pathLength="1"
          className="indicator"
          style={{ pathLength: scrollXProgress, stroke: 'blue', strokeWidth: '10', fill: 'none' }}
        />
      </svg>
      <ul 
        ref={ref} 
        className="horizontal-scroll" 
        style={{ 
          display: 'flex', 
          overflowX: 'auto', // Changed from 'scroll' to 'auto' for better scrollbar appearance
          padding: 0, 
          height: '100%', 
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none', // For Internet Explorer and Edge
          // Custom scrollbar styles
          scrollbarColor: 'blue darkblue', // For Firefox
        }}
      >
        {projects.map((project, index) => (
          <li 
            key={index} 
            className="card" 
            style={{ 
              listStyle: 'none', 
              border: `2px solid ${isDarkTheme ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}`, 
              margin: '20px', 
              padding: '10px', 
              minWidth: '250px', 
              minHeight: '350px', 
              boxSizing: 'border-box',
              borderRadius: '10px',
              flexShrink: 0, // Prevent shrinking to avoid vertical scroll
              display: 'flex', // Added flex display
              flexDirection: 'column', // Column direction
              justifyContent: 'space-between', // Space between elements
              alignItems: 'center' // Center align items
            }}
          >
            <h3 style={{ marginBottom: '5px' }}>{project.title}</h3>
            <p style={{ opacity: 0.6, marginTop: '0', fontSize: '0.8em' }}>{project.chain}</p>
            <img 
              src={project.image} 
              alt={project.title} 
              style={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'cover', borderRadius: '10px' }} 
            />
            <a href={project.link} target="_blank" rel="noopener noreferrer" style={{ marginTop: 'auto', display: 'flex', alignItems: 'center', color: isDarkTheme ? 'white' : 'black', textDecoration: 'none' }}>
              <LanguageIcon style={{ marginRight: '5px' }} />
              Website
            </a>
          </li>
        ))}
      </ul>
    </Container>
  );
}