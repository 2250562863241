import * as React from "react";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import One from "../static/images/slideshow/1.png";
import Two from "../static/images/slideshow/2.png";
import Three from "../static/images/slideshow/3.png";
import Four from "../static/images/slideshow/4.png";
import Five from "../static/images/slideshow/5.png";
import Six from "../static/images/slideshow/6.png";
import Seven from "../static/images/slideshow/7.png";
import Eight from "../static/images/slideshow/8.png";
import Nine from "../static/images/slideshow/9.png";
import { useMediaQuery } from "@mui/material";

export const images = [One, Two, Three, Four, Five, Six, Seven, Eight, Nine];

const variants = {
  enter: (direction: number) => {
    return {
      zIndex: 0,
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      transitionEnd: {
        zIndex: -1,
      },
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export default function Examples(): JSX.Element {
  const [[page, direction], setPage] = useState([0, 0]);
  const [isHovered, setIsHovered] = useState(false);
  const [dragConstraints, setDragConstraints] = useState({ left: 0, right: 0 });

  const resetDragConstraints = () => {
    setDragConstraints({ left: 0, right: 0 });
  };

  const resetPage = (newPage: number, newDirection: number) => {
    setPage([newPage, newDirection]);
    setTimeout(() => resetDragConstraints(), 100); // Delay the reset to ensure state update
  };

  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        paginate(1);
      }, 5000); // Slowed down the interval to 5000ms
      return () => clearInterval(interval);
    }
  }, [isHovered, page]);

  useEffect(() => {
    setDragConstraints({ left: 0, right: 0 });
  }, [page]);
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "100px",
      }}
    >
      <h2 style={{ textAlign: "center", fontFamily: "Zen Dots, sans-serif" }}>
        Some DApps developed by us:
      </h2>
      <div
        style={{
          position: "relative",
          width: "95%",
          maxWidth: "1000px",
          overflow: "hidden",
          height: "500px",
        }}
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            key={page}
            src={images[imageIndex]}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 200, damping: 50 }, // Adjusted for smoother transition
              opacity: { duration: 0.5 }, // Increased duration for smoother fade
            }}
            drag="x"
            dragConstraints={dragConstraints}
            dragElastic={1}
            dragTransition={{ bounceStiffness: 200, bounceDamping: 20 }}
            onDragEnd={(
              event,
              {
                offset,
                velocity,
              }: {
                offset: { x: number; y: number };
                velocity: { x: number; y: number };
              }
            ) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                resetPage(page + 1, 1);
              } else if (swipe > swipeConfidenceThreshold) {
                resetPage(page - 1, -1);
              } else {
                setTimeout(() => resetDragConstraints(), 100); // Delay the reset to ensure state update
                setPage([page, 0]);
              }
              (event.target as HTMLImageElement).style.transform =
                "translateX(0px)";
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              objectFit: isMobile ? "contain" : "cover",
            }}
          />
        </AnimatePresence>
        <div className="next" onClick={() => paginate(1)} style={arrowStyle}>
          {"ᐅ"}
        </div>
        <div
          className="prev"
          onClick={() => paginate(-1)}
          style={{ ...arrowStyle, left: "10px", right: "auto" }}
        >
          {"ᐊ"}
        </div>
        <div style={{ ...dotsContainerStyle, zIndex: 2 }}>
          {images.map((_, index) => (
            <div
              key={index}
              style={{
                ...dotStyle,
                backgroundColor:
                  index === imageIndex
                    ? "rgba(128, 128, 128, 0.9)"
                    : "rgba(128, 128, 128, 0.5)",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const arrowStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  right: "10px",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(128, 128, 128, 0.5)",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 2,
};

const dotsContainerStyle: React.CSSProperties = {
  position: "absolute",
  bottom: "10px",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  gap: "5px",
};

const dotStyle: React.CSSProperties = {
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: "rgba(128, 128, 128, 0.5)",
};
