import * as React from 'react';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Theme, useTheme } from '@mui/material/styles';
import TelegramIcon from '@mui/icons-material/Telegram';
import DiscordIcon from '../static/images/discord.png';
import Logo from '../static/images/cropped.png';

export default function Ambassadors() {

    const theme = useTheme() as Theme;
    return (
        <Container
            id="ambassadors"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                color: (theme) => theme.palette.mode === 'dark' ? 'white' : 'black',
                fontFamily: 'Zen Dots, sans-serif',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    textAlign: 'left',
                }}
            >
                <Typography 
                    component="h2" 
                    variant="h4" 
                    sx={{ 
                        fontFamily: 'Zen Dots, sans-serif',
                        color: (theme) => theme.palette.mode === 'dark' ? 'white' : 'black',
                    }}
                >
                    Our Official Ambassadors:
                </Typography>
                <Alert 
                    severity="warning" 
                    sx={{ 
                        fontFamily: 'Zen Dots, sans-serif',
                        mt: 2,
                    }}
                >
                    WARNING: NEVER SEND MONEY DIRECTLY TO THE AMBASSADORS
                </Alert>
                <Box
                    component={motion.div}
                    initial={{ backgroundColor: 'transparent' }}
                    whileHover={{
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(45, 0, 90, 0.8)' : 'rgba(105, 0, 170, 0.8)',
                        transition: { duration: 0.1 }
                    }}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 2,
                        p: 2, // Increased padding
                        width: 'fit-content',
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
                        borderRadius: 3, // Increased border-radius for more rounded corners
                        fontSize: '1.2rem', // Increased font size
                        flexDirection: 'column', // Stack items vertically
                        position: 'relative', // Added for overlay effect
                        overflow: 'hidden', // Ensure the overlay stays within the box
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: theme.palette.mode === 'dark' 
                                ? 'radial-gradient(circle at var(--x) var(--y), rgba(98, 0, 118, 0.5) 10%, rgba(45, 0, 90, 0.8) 10%)' 
                                : 'radial-gradient(circle at var(--x) var(--y), rgba(158, 0, 190, 0.5) 10%, rgba(105, 0, 170, 0.8) 10%)', // Adjusted colors for dark and light themes
                            opacity: 0,
                            pointerEvents: 'none',
                            zIndex: 0, // Ensure the background is behind the content
                            transition: 'opacity 2s ease-in-out',
                        },
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: theme.palette.mode === 'dark' 
                                ? 'rgba(0, 0, 0, 0.5)' // Dark overlay for dark theme
                                : 'rgba(0, 0, 0, 0.7)', // Even darker overlay for light theme
                            zIndex: 1, // Ensure the overlay is above the background
                            pointerEvents: 'none',
                        },
                        '&:hover::before': {
                            opacity: 1,
                        },
                    }}
                    onMouseMove={(e: { currentTarget: any; clientX: number; clientY: number; }) => {
                        const box = e.currentTarget;
                        const rect = box.getBoundingClientRect();
                        box.style.setProperty('--x', `${e.clientX - rect.left}px`);
                        box.style.setProperty('--y', `${e.clientY - rect.top}px`);
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            zIndex: 2, // Ensure content is above the dark overlay
                        }}
                    >
                        <Box 
                            component="img"
                            src={Logo}
                            sx={{ mr: 1, width: 40, height: 40 }}
                        /> 
                        <Typography sx={{ mr: 2, ml: 2, fontSize: '1.5rem', fontFamily: 'Zen Dots', color: 'white' }}>Magick.Web3</Typography>
                        <TelegramIcon 
                            sx={{ 
                                cursor: 'pointer', 
                                mr: 1, 
                                fontSize: '2rem', 
                                color: '#0088cc',
                                transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                                '&:hover': {
                                    transform: 'translateY(-5px)', // Elevate the icon on hover
                                }
                            }} 
                            onClick={() => window.open('https://t.me/magickweb3', '_blank')}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 1,
                            position: 'relative',
                            zIndex: 2, // Ensure content is above the dark overlay
                        }}
                    >
                        <Box 
                            component="img"
                            src={DiscordIcon}
                            sx={{ width: 28, height: 28 }} // Slightly smaller size
                        />
                        <Typography sx={{ ml: 1, fontSize: '1rem', fontFamily: 'Zen Dots', color: 'white' }}>@_magickweb3_</Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}