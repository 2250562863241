import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { motion } from "framer-motion";
import { keyframes } from "@emotion/react";
import discordIcon from "../static/images/discord.png";
import TelegramIcon from "@mui/icons-material/Telegram"; // Import Telegram icon from Material UI

// Import images
import exitImage from "../static/images/profile/exit.png";
import keepeetoImage from "../static/images/profile/keepeeto.png";
import jmauixImage from "../static/images/profile/jmauix.png";
import psilo_tkImage from "../static/images/profile/psilo.png";
import magick_web3Image from "../static/images/profile/magick.png";
import { useMediaQuery } from "@mui/material";

export default function AboutUs() {
  const [isOpen, setIsOpen] = useState(false);

  const transition = {
    duration: 1.5,
  };

  const summary = `
    ETHERCODE was founded to meet the need for custom Web3 solutions.
    Exit and Keepeeto, with strong IT backgrounds and...
    `;

  const long = `
    ${summary}  a passion for programming, blockchain, and gaming, became friends and collaborators. 
     Starting from scratch, they specialized in backend (smart contracts) and frontend (decentralized applications) development. 
     ETHERCODE is now expanding, aiming to grow, learn, and establish a global presence while inviting other developers to contribute. 
    Their vision includes developing new technologies and protocols for the blockchain world.`;

  const colorTransition = keyframes`
        0% { color: gray; }
        50% { color: blue; }
        100% { color: gray; }
    `;

  const teamCardColors = [
    "#0000FF",
    "#4B0082",
    "#8A2BE2",
    "#9400D3",
    "#8A2BE2",
    "#4B0082",
    "#0000FF",
    "#00BFFF",
    "#1E90FF",
    "#87CEFA",
    "#4682B4",
    "#6495ED",
  ];
  const teamMembers = [
    "E X I T",
    "KEEPEETO",
    "JMAUIX",
    "PSILO_TK",
    "MAGICK_WEB3",
  ];
  const teamRoles = [
    "Founder & DEV",
    "Founder & DEV",
    "FullStack Developer",
    "FullStack Developer",
    "Marketing Manager",
  ];
  const teamImages = [
    exitImage,
    keepeetoImage,
    jmauixImage,
    psilo_tkImage,
    magick_web3Image,
  ];
  const teamDiscords = [
    "@ethercode_exit",
    "@ethercode_keepeeto",
    "@jmauix",
    "psilo",
    "@_magickweb3_",
  ];
  const teamTelegrams = [
    "https://t.me/ethercode_exit",
    "https://t.me/ethercode_0xKpt",
    "https://t.me/xArmOrer",
    "https://t.me/",
    "https://t.me/magickweb3",
  ];
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <motion.div
      id="about"
      layout
      transition={{ duration: 1.5 }}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "black",
        color: "white",
        fontFamily: "Zen Dots, sans-serif",
        paddingTop: "75px",
        paddingBottom: "100px",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "black",
          color: "white",
          fontFamily: "Zen Dots, sans-serif",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          sx={{
            fontFamily: "Zen Dots, sans-serif",
          }}
        >
          About Us
        </Typography>
        <motion.div
          layout
          transition={transition}
          onClick={() => setIsOpen(!isOpen)}
          style={{
            marginTop: "60px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            cursor: "pointer",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "600px",
            overflow: "hidden",
            transition: "height 0.5s ease",
            alignSelf: "center",
          }}
        >
          <motion.div
            layout="position"
            transition={transition}
            style={{ flex: 1 }}
          >
            <Typography
              component="p"
              variant="body1"
              sx={{
                fontFamily: "Zen Dots, sans-serif",
                color: "black",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              {isOpen ? long : summary}
            </Typography>
            <Typography
              component="p"
              variant="body2"
              sx={{
                fontFamily: "Zen Dots, sans-serif",
                color: "gray",
                marginTop: "10px",
                animation: `${colorTransition} 3s ease-in-out infinite`,
                textAlign: "center",
              }}
            >
              {isOpen ? "Click to collapse" : "Click to expand"}
            </Typography>
          </motion.div>
        </motion.div>
        <Typography
          component="h2"
          variant="h3"
          sx={{
            fontFamily: "Zen Dots, sans-serif",
            color: "white",
            marginTop: "100px", // Add sufficient space below the letter
            textAlign: "center",
          }}
        >
          Team
        </Typography>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "50px",
          }}
        >
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              className="team-card"
              animate={{ backgroundColor: teamCardColors }}
              transition={{
                duration: 15,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "reverse",
                delay: index * 1.5,
              }}
              style={{
                width: isMobile ? "100%" : "300px",
                height: "300px",
                margin: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column", // Added to stack items vertically
                justifyContent: "flex-start", // Align items to the top
                alignItems: "center",
                color: "white",
                fontFamily: "Zen Dots, sans-serif",
                position: "relative", // Add position relative for overlay
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.7)", // Slightly darker overlay
                  borderRadius: "10px",
                }}
              ></div>
              <div
                style={{
                  position: "relative",
                  zIndex: 1,
                  marginTop: "30px", // Add margin to separate from the top
                  textAlign: "center", // Center the text
                  fontSize: "1.1rem", // Increase font size
                }}
              >
                {member}
              </div>
              <Typography
                component="p"
                variant="body2"
                sx={{
                  fontFamily: "Zen Dots, sans-serif",
                  color: "white",
                  marginTop: "5px",
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                {teamRoles[index]}
              </Typography>
              <img
                src={teamImages[index]}
                alt={`${member} profile`}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginTop: "20px", // Increased margin-top
                  zIndex: 1,
                }}
              />
              {member !== "PSILO_TK" && (
                <>
                  <div
                    style={{
                      position: "relative",
                      zIndex: 1,
                      marginTop: "20px", // Increased margin to separate from the image
                      textAlign: "center", // Center the text
                      fontSize: member === "KEEPEETO" ? "0.7rem" : "0.8rem", // Reduce font size for Discord username of KEEPEETO
                    }}
                  >
                    <img
                      src={discordIcon}
                      alt="Discord icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                        verticalAlign: "middle",
                      }}
                    />
                    {teamDiscords[index]}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      zIndex: 1,
                      marginTop: "5px", // Reduced margin to separate from the Discord username
                      textAlign: "center", // Center the text
                      fontSize: "0.8rem", // Further reduce font size for Telegram username
                    }}
                  >
                    <a
                      href={teamTelegrams[index]}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "white", // Change text color to white
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transition: "transform 0.2s", // Add transition for elevation
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "translateY(-3px)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "translateY(0)")
                      }
                    >
                      <TelegramIcon
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                          verticalAlign: "middle",
                          color: "#0088cc", // Change icon color to light blue
                        }}
                      />
                      Telegram
                    </a>
                  </div>
                </>
              )}
            </motion.div>
          ))}
        </div>
      </Container>
    </motion.div>
  );
}
