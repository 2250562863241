import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HandIcon from '../static/images/handshake.png';
import DevelopIcon from '../static/images/develop.png';
import UploadIcon from '../static/images/upload.png';
import { TransitionProps } from '@mui/material/transitions';
import Collapse from '@mui/material/Collapse';

function useViewportDimensions() {
    const [dimensions, setDimensions] = React.useState({ width: window.innerWidth, height: window.innerHeight });

    React.useEffect(() => {
        const handleResize = () => {
            setDimensions({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return dimensions;
}

function SlowAccordion(props: TransitionProps) {
    return <Collapse {...props} timeout={1000} />;
}

export default function Highlights() {
    const theme = useTheme();
    const viewport = useViewportDimensions();

    const [background, setBackground] = React.useState(
        `conic-gradient(from 0deg at 50% 50%, cyan, magenta, yellow, cyan)`
    );

    const overlayOpacity = theme.palette.mode === 'dark' ? 0.7 : 0.6;
    const accordionBgColor = theme.palette.mode === 'dark' ? 'transparent' : 'rgba(255, 255, 255, 0.2)';

    React.useEffect(() => {
        const handlePointerMove = (e: { clientX: number; clientY: number; }) => {
            const x = e.clientX / viewport.width;
            const y = e.clientY / viewport.height;
            setBackground(`conic-gradient(from 0deg at ${x * 100}% ${y * 100}%, cyan, magenta, yellow, cyan)`);
        };

        window.addEventListener('pointermove', handlePointerMove);
        return () => window.removeEventListener('pointermove', handlePointerMove);
    }, [viewport]);

    return (
        <div
            id="whyus"
            style={{ position: 'relative', background }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    bgcolor: `rgba(0, 0, 0, ${overlayOpacity})`, // Dynamic overlay opacity
                    zIndex: 1,
                }}
            />
            <Box
                id="highlights"
                sx={{
                    position: 'relative',
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                    color: 'white',
                    zIndex: 2,
                    // bgcolor: 'hsl(220, 30%, 2%)', // Removed static background color
                }}
            >
                <Container  
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: { xs: 3, sm: 6 },
                    }}
                >
                    <Box
                        sx={{
                            width: { sm: '100%', md: '80%' }, // Adjusted width to be wider
                            textAlign: { sm: 'left', md: 'center' },
                        }}
                    >
                        <Typography component="h2" variant="h4" sx={{ fontFamily: 'Zen Dots, sans-serif', mb: 4 }}>
                            Why choose us?
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'white', fontFamily: 'Zen Dots, sans-serif' }}>
                        Choosing us means opting for a partnership based on solid values of professionalism, safety and quality, 
                        with unparalleled attention to client needs. What sets us apart is our sincere commitment to providing excellent service tailored to each project.
                         Our team consists solely of 5 guys, but behind us is boundless determination and passion. 
                         We have chosen to keep our size small in order to offer highly personalized service focused on customer satisfaction. 
                         With us, you can count on dedicated support and constant attention to detail. We are ready to show you that big things can come from small teams.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: { sm: '100%', md: '60%' },
                            textAlign: { sm: 'left', md: 'center' },
                            mt: 4,
                        }}
                    >
                        <Typography component="h2" variant="h4" sx={{ fontFamily: 'Zen Dots, sans-serif', mb: 2 }}>
                            How we work
                        </Typography>
                        <Accordion TransitionComponent={SlowAccordion} sx={{ bgcolor: accordionBgColor }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ fontFamily: 'Zen Dots, sans-serif', color: 'white' }}>Phase 1</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    component="img"
                                    src={HandIcon}
                                    alt="Handshake"
                                    sx={{
                                        display: 'block',
                                        margin: '20px auto',
                                        width: '250px',
                                        height: 'auto',
                                    }}
                                />
                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.8)', fontFamily: 'Zen Dots, sans-serif' }}>
                                We are committed to building a solid trust relationship with you because we believe it's the crucial first step in initiating any kind of relationship, especially in the working context.
                                 To realize your project as accurately as possible, we need to know all the details and specifications you have in mind. 
                                 We'll be ready to ask all the necessary questions to fully understand your needs.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionComponent={SlowAccordion} sx={{ bgcolor: accordionBgColor }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ fontFamily: 'Zen Dots, sans-serif', color: 'white' }}>Phase 2</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    component="img"
                                    src={DevelopIcon}
                                    alt="Develop"
                                    sx={{
                                        display: 'block',
                                        margin: '20px auto',
                                        width: '250px',
                                        height: 'auto',
                                    }}
                                />
                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.8)', fontFamily: 'Zen Dots, sans-serif' }}>
                                Once the quote is accepted, we'll keep you constantly updated on the progress of the work.
                                 We'll start by defining the backend logics and then shaping the frontend. 
                                 We follow a methodical approach: we think, develop, test. 
                                 Our programming complies with blockchain security standards, and we perform all necessary tests to ensure the proper functioning of Smart Contracts and DApps.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionComponent={SlowAccordion} sx={{ bgcolor: accordionBgColor }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ fontFamily: 'Zen Dots, sans-serif', color: 'white' }}>Phase 3</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    component="img"
                                    src={UploadIcon}
                                    alt="Upload"
                                    sx={{
                                        display: 'block',
                                        margin: '20px auto',
                                        width: '200px', // Adjusted width to be smaller
                                        height: 'auto',
                                    }}
                                />
                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.8)', fontFamily: 'Zen Dots, sans-serif' }}>
                                Once the project is ready, you'll have the opportunity to test it on the Testnet to verify its quality and effectiveness.
                                 Subsequently, we'll make it available on the Mainnet for a full launch. 
                                 Our commitment to you doesn't end with the conclusion of the project. 
                                 We're here to assist you with any needs and resolve any issues you may encounter even after delivery.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}