import React, { useEffect } from "react";
import "./App.css";
import AppAppBar from "./components/AppAppBar";
import {
  Box,
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  useMediaQuery,
  Theme,
} from "@mui/material";
import getLPTheme from "./getLPTheme";
import Features from "./components/Values";
import Works from "./components/Works";
import Examples from "./components/Examples";
import WhyUs from "./components/WhyUs";
import Ambassodors from "./components/Ambassadors";
import AboutUs from "./components/AboutUs";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeHelper } from "./classes/ThemeHelper";
import DatabaseHelper from "./classes/DatabaseHelper";
import AddressFactory from "./common/AddressFactory";
import { EtherContextRepository } from "./helper/EtherContextRepository";
import { EtherContext } from "./helper/EtherContext";
import QuoteRequestForm from "./components/Forms/FormQuote";
import { darkTheme, lightTheme } from "./theme/Theme";

function WrapperApp() {
  return (
    <>
      <Hero />
      <Features />
      <Works />
      <Examples />
      <WhyUs />
      <Ambassodors />
      <AboutUs />
      <Footer />
    </>
  );
}

function App({
  setMode,
  mode,
}: {
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
  mode: PaletteMode;
}) {
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(true);
  const dataHelper = new DatabaseHelper(AddressFactory.getDBEndpoint(true));
  const { context, saveContext } = React.useContext(
    EtherContext
  ) as EtherContextRepository;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md")
  );

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
    ThemeHelper.setTheme(mode);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const userData = await dataHelper.getMe(token);
          setUser(userData);
          console.log(userData);
        } catch (error) {
          //localStorage.removeItem('token');
          console.log(error);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("context", context);
    console.log(localStorage.getItem("token"));
  }, []);

  function closeAlert() {
    setAlert(false);
  }

  return (
    <ThemeProvider theme={mode === "light" ? lightTheme : darkTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} user={user} />
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
        <Router>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "visible",
            }}
          >
            <Routes>
              <Route path="/" element={<WrapperApp />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
