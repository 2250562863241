import React from 'react';
import { Modal, Box, Typography, Divider, Grid, Avatar, Card, CardHeader, CardActionArea, Button, CardMedia, ToggleButton } from '@mui/material';
import LogoP from '../../static/images/cropped.png'
import { AccountCircle, Edit, ManageAccounts, PriceChange, VerifiedUser, Visibility } from '@mui/icons-material';
import { CardWallet } from '../Wallet/CardWallet';
import { LineChart } from '@mui/x-charts/LineChart';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const samplebonus = [1, 10, 12, 17, 18, 23, 25];
const sample_ceth = [0.0002, 0.0013, 0.002, 0.0028, 0.0030, 0.0032, 0.0034];

interface UserModalProps {
    open: boolean;
    onClose: () => void;
    email: string;
    wallet: string;
    username?: string;
}

const UserModal: React.FC<UserModalProps> = ({ open, onClose, email, wallet, username }) => {
    const [isFullData, setIsFullData] = React.useState(false)

    const onChangeSwitch = () => {
        setIsFullData(!isFullData)
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '100%', md: '100%', lg: '100%' },
                height: { xs: '100%', md: '100%', lg: '100%' },
                alignItems: 'center'
            }}
        >
            <Box sx={{
                width: { xs: 360, md: 600, lg: 700 },
                bgcolor: 'black',
                borderRadius: 2,
                border: '1px solid white',
                boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                p: 2,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ alignItems: 'center' }}>
                        <Avatar sx={{ width: 60, height: 60, margin: 'auto' }} src={LogoP} />
                        <Divider sx={{ my: 2, display: 'flex', flexDirection: 'row' }}><Typography sx={{ alignSelf: 'center', }} variant="h6">{username?.trim() === '' ? email.split('@')[0].toUpperCase() : username} </Typography></Divider>
                        <CardWallet />
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 5
                                    }}>
                                        <AccountCircle sx={{ alignSelf: 'center' }} />
                                        <Typography sx={{ alignSelf: 'center' }} variant="h6">My Profile</Typography>
                                    </div>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<Visibility />}
                                    >
                                        Visit
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 5
                                    }}>
                                        <ManageAccounts sx={{ alignSelf: 'center' }} />
                                        <Typography sx={{ alignSelf: 'center' }} variant="h6">Edit Profile</Typography>
                                    </div>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<Edit />}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ mt: 1 }}>Full Data</Typography>
                                <IOSSwitch sx={{ m: 1 }} defaultChecked onChange={onChangeSwitch} />
                                <Typography sx={{ mt: 1 }}>Last Week</Typography>
                            </div>
                        </Divider>
                        {isFullData
                            ?
                            (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>

                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>$cETH EARNED</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>0.50538</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>RAID BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x3.4</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>LAUNCHPAD BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x1.5</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>ICO BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x10</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>TRADE BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x6.7</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>REF BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x3.3</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>REF CODE</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>K33Pp7zz_203B</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>TOTAL BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x24.9</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>

                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>$cETH EARNED</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>0.00341</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>RAID BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x3.4</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{ border: '2px solid rgba(2, 31, 59, 0.65)', borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 2, justifyContent: 'center', bgcolor: 'black', color: 'white', boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)' }}>
                                            <Typography variant='body1' sx={{ alignSelf: 'center' }}>TOTAL BONUS</Typography>
                                            <Typography variant='h6' sx={{ alignSelf: 'center', fontWeight: 'bold' }}>x24.9</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ width: '100%' }}>
                                            <LineChart
                                                xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7], label: 'Time' }]}
                                                yAxis={[
                                                    { id: 'bonusAxis', label: 'Bonus', scaleType: 'linear' },
                                                    { id: 'cethAxis', label: 'cETH', scaleType: 'linear' },
                                                ]}
                                                series={[
                                                    { yAxisKey: 'bonusAxis', data: samplebonus, label: 'Bonus' },
                                                    { yAxisKey: 'cethAxis', data: sample_ceth, label: 'cETH' },
                                                ]}
                                                leftAxis="bonusAxis"
                                                rightAxis="cethAxis"
                                                height={230}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default UserModal;